import React, { Component } from 'react'

import wccLogo from '../../assets/img/token-logo-256.png'

import twitterLogo from '../../assets/img/social/twitter.png'
import facebookLogo from '../../assets/img/social/facebook.png'
import instagramLogo from '../../assets/img/social/instagram.png'

class LandingPage extends Component {
  render() {
    return (
      <div>
        <div className="section landing-bg valign-wrapper" style={{ minHeight: "100vh" }}>
          <div className="center-align" style={{ width: "100%"}}>
            <div className="row">
              <div className="col s12">
                <img src={wccLogo} alt="WCC Logo" ></img>
              </div>
            </div>
            <div className="row">
              <h2 className="col s12 wcc-white">
                World Cooperation Currency
          </h2>
            </div>
            <div className="row">
              <div className="col s12">
                <a href="#whatiswcc" className="btn-large wcc-green-bg">Learn more</a>
              </div>
            </div>
          </div>
        </div>
        <div id="whatiswcc" className="section wcc-white-bg">
          <div className="container">
            <div className="row">
              <div className="col s12">
                <h4 className="wcc-black">What is WCC?</h4>
                <p className="wcc-black">
                  World Cooperation Currency, or <b>WCC</b> for short, is a cryptocurrency tied to <a href="https://sustainabledevelopment.un.org/">
                    UN's 17 Sustainable Development Goals</a> (SDG's), but more importantally an incitement for the world to
        come together and work with the 2030 Agenda.
                </p>
                <div className="row">
                  <div className="col s12 m4">
                    <h5>How?</h5>
                    <p>
                      By distributing WCC to those who are working with SDG's we hope to incite more people to get involved
                      to make the world a better place for everyone.
                  </p>
                    <p>
                      This wouldn't be possible without modern technologies like
                    <a href="https://en.wikipedia.org/wiki/Cryptocurrency"> cryptocurrencies</a> and the
                    <a href="https://en.wikipedia.org/wiki/Blockchain"> blockchain</a>.
                    By issuing <a href="https://en.wikipedia.org/wiki/Smart_contract">smart contracts</a> on the blockchain we can ensure that SDG workers get WCC
                                and that their efforts really addresses the SDG's.
                  </p>
                  </div>
                  <div className="col s12 m4">
                    <h5>When?</h5>
                    <p>
                      Our plan is to make WCC available for everyone early 2020 and we are looking for early birds
                      and partners to help us get started. If you have any questions contact us via our social media channels or send us an email.
                    </p>
                  </div>
                  <div className="col s12 m4">
                    <h5>White paper</h5>
                    <p>
                      Coming soon.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section wcc-green-bg" style={{ backgroundPosition: "top" }}>
          <div className="row" style={{ backgroundColor: "rgb(52, 46, 55, 0.5)", height: "100%", display: "inline" }}>
            <div className="col l2 hide-on-med-and-down">
              <img className="hide-on-med-and-down" src={wccLogo} alt="WCC Logo" />
            </div>
            <div className="col s12 m6 l5 wcc-white">
              <h4>Projects</h4>
              <p>
                Organizations & projects will be elligible to recieve WCC if they are actively working
                with UN's Sustainable Development Goals. If you are intrested contact us at hello@wcc.earth.
              </p>
            </div>
            <div className="col s12 m6 l5 wcc-white">
              <h4>Connect with us!</h4>
              <p>
                Follow and connect with us via our official channels in social media.
              </p>
              <div className="row center-align">
                <i className="col s2 m1 fab fa-facebook fa-2x"></i>
                <i className="col s2 m1 fab fa-twitter fa-2x"></i>
                <i className="col s2 m1 fab fa-instagram fa-2x"></i>
                {/* <div className="col s4"><img src={twitterLogo} alt="Twitter" width="48" /></div>
                <div className="col s4"><img src={facebookLogo} alt="Facebook" width="48" /></div>
                <div className="col s4"><img src={instagramLogo} alt="Instagram" width="48" /></div> */}
              </div>
            </div>
          </div>
        </div>
        <div id="modal-signup" className="modal">
          <div className="modal-content">
            <h4>Sign up</h4>
            <p>
              We are not quite ready yet.
            </p>
            <p>
              To get the latest news about WCC and maybe an invitation to our ICO's,
              enter your email below.
            </p>
            <div className="input-field row">
              <input className="col s12" type="email" name="email" id="email" />
              <label htmlFor="email">Your email</label>
              <div className="col s12 btn-large wcc-green-bg" style={{ marginTop: "2rem" }}>Register</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LandingPage