import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Navbar from './components/navbar/navbar'
import M from 'materialize-css'
import LandingPage from './components/landing/landing'
import SignUp from './components/landing/signup'
import CreateAccount from './components/landing/accountcreate'

class App extends Component {
  componentDidMount() {
    M.AutoInit()
  }

  render() {
    return (
      <Router>
        <div className="App">
          <header>
            {/* <Navbar /> */}
          </header>
          <main>
            {/*<Route path="/" exact component={ LandingPage } />
            <Route path="/signup" component={ SignUp } />
            <Route path="/create" component={ CreateAccount } />*/}
            <LandingPage />
          </main>
          <footer className="page-footer wcc-green-bg hide">
            <div className="footer-content container">
              <p>
                Contact us at hello@wcc.earth
              </p>
            </div>
            <div className="footer-copyright">
            <p>© 2019 BGCI AB</p>
            </div>
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
