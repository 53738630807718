import React, { Component} from 'react'

import ribbonStripes from '../../assets/img/ribbon-stripes.png'

class SignUp extends Component {
  render() {
    return (
      <>
      <div className="section center-align">
        <h2 className="wcc-white">
        Create your account
        </h2>
      </div>
      <div className="section wcc-white-bg">
        <div className="container">
          <div className="row">
            <p>
              To use the WCC Wallet you need to purchase a minimum of 1 WCC. 
              You can purchase WCC with Bitcoin or Swish.
            </p>
            <form>
              <h4 className="col s12 center-align">How would you like to pay?</h4>
              
                <div className="input-field col s12 m5">
                  <input placeholder="Your phone number (Swish)" id="phone" type="text" className="validate" />
                  <label htmlFor="phone">Swish</label>
                  <br /><br />
                  <a href="/create" className="btn wcc-green-bg">Pay with Swish</a>
                </div>
                <h4 className="col s12 m2 center-align">Or</h4>
                <div className="input-field col s12 m5">
                  <input placeholder="Your Bitcoin address" id="btc-address" type="text" className="validate" />
                  <label htmlFor="btc-address">Bitcoin</label>
                  <br /><br />
                  <a href="/create" className="btn wcc-green-bg">Pay with Bitcoin</a>
                </div>
              
            </form>
          </div>
        </div>
      </div>
      <div className="section ribbon-stripes"></div>
      </>
    )
  }
}

export default SignUp