import React, { Component } from 'react'
import M from 'materialize-css'
import LandingPage from '../landing/landing'
import wccLogo from '../../assets/img/token-logo.png'

class Navbar extends Component {
  componentDidMount() {
    M.AutoInit()
  }
  
  render() {
    return (
      <>
        <nav className="transparent z-depth-0">
          <div className="nav-wrapper">
            <a href="#" data-target="mobile-menu" className="sidenav-trigger"><i className="material-icons">menu</i></a>
            <ul id="nav-mobile-green" className="right hide-on-med-and-down">
              {/* <li><a href="https://wcc-wallet-f8fc4.web.app/">Wallet</a></li> */}
              {/*<li><a href="/organizations">Organizations & projects</a></li>
              <li><a href="#about">About</a></li>*/}
            </ul> 
          </div>
        </nav>

        <ul className="sidenav wcc-black-bg" id="mobile-menu">
          <li>
            <img src={ wccLogo } alt="WCC Wallet" style={{ padding: "20 0 5 28"}} />w
          </li>
          {/* <li><a href="https://wcc-wallet-f8fc4.web.app/">Wallet</a></li> */}
          {/*<li><a href="/organizations">Organizations & projects</a></li>
          <li><a href="#about">About</a></li>*/}
        </ul>
      </>
      )
  }
}

export default Navbar