import React, { Component} from 'react'
import firebase from 'firebase/app'
// Required for side-effects
import '@firebase/functions'


class CreateAccount extends Component {
  state = {
    title: "Creating your account...",
    publickey: null,
    secret: null,
    waiting: true
  }

  componentDidMount() {
    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyCaGJyaCXymsbV4o2x6FBLnZSSVGdOVFxA",
      authDomain: "causal-root-161422.firebaseapp.com",
      databaseURL: "https://causal-root-161422.firebaseio.com",
      projectId: "causal-root-161422",
      storageBucket: "causal-root-161422.appspot.com",
      messagingSenderId: "403977075339",
      appId: "1:403977075339:web:3cbe37529fb2332e"
    };
    // Initialize Firebase
    var functions = firebase.initializeApp(firebaseConfig).functions('europe-west1');

    var createTestAccount = functions.httpsCallable("createTestAccount");
    createTestAccount().then(result => {
      console.log("Result:", result.data)
      this.setState({
        title: "Your account is now created!",
        publickey: result.data.publickey,
        secret: result.data.secret,
        waiting: false
      })
    });
  }

  render() {
    return (
      <>
      <div className="section center-align">
        <h2 className="wcc-white center-align">
          {this.state.title}
        </h2>
          {this.state.waiting ? <div className="preloader-wrapper big active">
                <div className="spinner-layer spinner-green-only">
                  <div className="circle-clipper left">
                    <div className="circle"></div>
                  </div><div className="gap-patch">
                    <div className="circle"></div>
                  </div><div className="circle-clipper right">
                    <div className="circle"></div>
                  </div>
                </div>
              </div> :
          <i className="material-icons large wcc-green">done</i>}
      </div>
      {this.state.waiting ? 
        null
        :<>
      <div className="section wcc-white-bg">
        <div className="container center-align">
        </div>
        <div className="row">
          <div className="col s12 center-align">
              <div className="container">
                <p>REMEMBER TO KEEP YOUR SECRET IN A SAFE PLACE!</p>
                <h5>Your public key</h5>
                <textarea className="wcc-green-bg wcc-white center-align" style={{ padding: "10px", fontFamily: "monospace", fontSize: "120%"}} disabled>{this.state.publickey}</textarea>
                <h5>Your secret</h5>
                <textarea className="wcc-green-bg wcc-white center-align" style={{ padding: "10px", fontFamily: "monospace", fontSize: "120%"}} disabled>{this.state.secret}</textarea>
                <div className="row" style={{marginTop: "50px"}}>
                  <a href="/wallet" className="btn-large wcc-green-bg wcc-white">Go to your wallet</a>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="section ribbon-stripes"></div>
      </>
      }
      </>
    )
  }
}

export default CreateAccount